<template>
  <div class="px-5 py-5 mt-16 sm:mx-0 md:mx-5 lg:mx-20%">
    <da-history-header :px="px" v-model="isEditProfile" v-on:edit="isEditProfile = true"></da-history-header>
    <div v-if="!isEdit" :class="{'animate-fade-in-left ease-in-out': !isEdit}">
        <div class="shadow-md bg-white mt-5 rounded-tl-lg rounded-tr-lg">
            <v-tabs 
            v-model="tab" 
            color="#BC890A" 
            background-color="#F4D16E" 
            next-icon="mdi-chevron-right"
            prev-icon="mdi-chevron-left"
            active-class="active-tab"
            :show-arrows="true"
            center-active
            hide-slider
            grow
            class="rounded-tl-lg rounded-tr-lg" 
            v-if="configuration && configuration.length > 0" 
           >
                <v-tab dark  v-for="(item, i)  in configuration" :key="i" class="capitalize">
                    {{item.name ? item.name : ''}}
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item >
                    <da-medical-history v-if="tab == 0" :px="px" v-on:edit="isEdit = true" v-on:delete="isDelete = true"></da-medical-history>
                </v-tab-item>
                <v-tab-item >
                   <da-odontogram :px="px" v-if="tab == 1"></da-odontogram>
                </v-tab-item>
                <v-tab-item >
                   <da-patient-photo v-if="tab == 2" :px="px" v-on:edit="isEdit = true"></da-patient-photo>
                </v-tab-item>
                <v-tab-item >
                    <da-medical-monitoring v-if="tab == 3" :px="px"  v-on:delete="isDelete = true"></da-medical-monitoring>
                </v-tab-item>
                <v-tab-item >
                   <da-patient-treatment v-if="tab == 4" :px="px" ></da-patient-treatment>
                </v-tab-item>
            </v-tabs-items>
        </div>
        
    </div>
    
    <div v-if="isEdit" class="py-5" :class="{'animate-fade-in-left ease-in-out': isEdit}">
        <da-patient-form :id="id" v-on:cancel="onCancelEdit"></da-patient-form>
    </div>

    <da-dialog v-model="isDelete" :persist="true" :maxWidth="'500px'">
        <template #body>
            <da-sweet-confirm 
            :title="'Eliminar Paciente'" 
            :message="'¿Esta seguro de eliminar los datos del paciente seleccionado?'"
            :lottiepath="DELETE"
            :iconwidth="150"
            :iconheight="150"
            v-on:confirm="onFinish(true)" 
            v-on:close="isDelete = false">
        </da-sweet-confirm>
        </template>
  </da-dialog>

  <da-dialog v-model="isEditProfile" :persist="true" :maxWidth="'500px'">
        <template #body>
            <da-sweet-confirm 
            :title="'Foto de Perfil'" 
            :message="'¿Deseas editar la foto de perfil del paciente?'"
            v-on:confirm="isUploadFile = true" 
            v-on:close="isEditProfile = false">
        </da-sweet-confirm>
        </template>
  </da-dialog>

  <da-upload-file :show="isUploadFile" v-on:close="isUploadFile = false" v-on:save="onSavePicture"></da-upload-file>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import VuexMixin from '../../mixins/VuexMixin';
import moduleTypes from '../../store/modules/common/module-types';
import fnTypes from '../../store/modules/common/fn-types';
import MedicalHistory from './Sections/MedicalHistory.vue';
import PhotoManager from './Sections/PhotoManager.vue';
import monitoringPlan from './Sections/MonitoringPlan.vue';
import DAHistoryHeader from './components/DAHistoryHeader.vue';
import PatientForm from './PatientForm.vue';
import TreatmentPlan from './Sections/TreatmentPlan.vue';
import Odontogram from './Sections/Odontogram.vue';
import DASweetConfirm from '../../core/components/form/dialog/components/DASweetConfirm.vue';
import { DELETE } from '../../utils/lottieicon';
import DAUploadFile from './profile/DAUploadFile.vue';
import DAProfileModel from '../../models/patient/DAProfile.model';


export default {
    mixins: [VuexMixin],
    props: {
        id: {
            type: [String],
            default: ''
        }
    },
    data() {
        return {
            isEdit: false,
            isDelete: false,
            isEditProfile: false,
            isUploadFile: false,
            DELETE,
            tab: 0,
            configuration: [
                {name: 'Historial Clinico'},
                {name: 'Odontograma'},
                {name: 'Fotos'},
                {name: 'Seguimiento'},
                {name: 'Tratamiento'},
            ],
            profile: new DAProfileModel(),
        }
    },
    components: {
        'da-medical-history': MedicalHistory,
        'da-history-header': DAHistoryHeader,
        'da-patient-form': PatientForm,
        'da-patient-treatment': TreatmentPlan,
        'da-patient-photo': PhotoManager,
        'da-sweet-confirm': DASweetConfirm,
        'da-upload-file': DAUploadFile,
        'da-medical-monitoring': monitoringPlan,
        'da-odontogram': Odontogram
    },
    mounted() {
      this.init()
  },
  methods: {
    ...mapActions(moduleTypes.PX, [
            fnTypes.getById
    ]),
    init: async function() {
        await this.ActionWrapp(moduleTypes.PX, fnTypes.getById, this.id)
    },
    onCancelEdit: function() {
        this.isEdit = false;
        //this.init();
    },
    onFinish: async function(value) {
        if(value) {
            await this.ActionWrapp(moduleTypes.PX, fnTypes.remove, this.id)
        }
    },
    onSavePicture: async function(event) {
      if(event) {
          this.profile.id_person = this.px.person ? this.px.person.id_person : null;
          this.profile.file = event;
          await this.ActionWrapp(moduleTypes.PX, fnTypes.uploadProfile, {data: this.profile, isnew: false});
          this.isUploadFile = false
      }
    },
  },
  computed: {
        ...mapGetters(moduleTypes.PX, {
            px: fnTypes.getPx,
        }),

    },
    watch:  {
        isUploadFile: function() {
            if(this.isUploadFile) {
                this.isEditProfile = false
            }
        }
    }

}
</script>

<style scoped>

.active-tab {
    background-color: rgb(253, 236, 189);
    box-shadow: 3px 2px 2px rgba(134, 134, 134, 0.5);
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.v-tab {
    text-transform: none !important;
    font-family: sans-serif;
    font-weight: 600;
    letter-spacing: 0.1rem;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    box-shadow: 3px 2px 2px rgba(134, 134, 134, 0.5);
}

</style>